import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"
import SignUpForm from "../components/SignUpForm/SignUpForm"

const signUpSources = {
  ITWA: "90 Day Free Trial for Indiana Towing & Wrecker Association!",
  APTRA:
    "90 Day Free Trial for Arizona Professional Towing & Recovery Association!",
  TRPC: "90 Day Free Trial for Towing and Recovery Professionals of Connecticut!",
  TRAG: "90 Day Free Trial for Towing and Recovery Association of Georgia!",
  NSTA: "90 Day Free Trial for Nevada State Tow Association!",
  STA: "90 Day Free Trial for Statewide Towing Association!",
  OWOA: "90 Day Free Trial for Oklahoma Wrecker Owners Association!",
  APTO: "90 Day Free Trial for Association of Professional Towers!",
  TTTA: "90 Day Free Trial for Tennessee Tow Truck Association!",
  UPTA: "90 Day Free Trial for Utah Professional Towing Alliance!",
  CTTA: "90 Day Free Trial for California Tow Truck Association!",
  ESTRA: "90 Day Free Trial for Empire State Towing & Recovery Association!",
  VATRO:
    "90 Day Free Trial for Virginia Association of Towing & Recovery Operators!",
  GSTA: "90 Day Free Trial for Garden State Towing Association!",
  PTA: "90 Day Free Trial for Pennsylvania Towing Association!",
  TRPM: "90 Day Free Trial for Towing & Recovery Professionals of Maryland!",
  NMTA: "90 Day Free Trial for New Mexico Trucking Association!",
  WTA: "90 Day Free Trial for Wisconsin Towing Association!",
  WHEELZY: "90 Day Free Trial for Wheelzy service providers!",
  MWG: "6-Month Free Trial for AAA MWG Service Providers!",
  "AAA-INC": "6-Month Free Trial for AAA Service Providers!",
  PTAO: "90 Day Free Trial for Provincial Towing Association of Ontario!",
  ROADAIDER: "90 Day Free Trial for Road Aider service providers!",
  SUREECOSYSTEM: "Special Free Trial for sureECOSYSTEM Service Providers!",
  SSTA: "90 Day Free Trial for Sunshine State Towing Association!",
  HERTZ: "90 Day Free Trial for Hertz Service Providers!",
  MUSEUM: "90 Day Free Trial for Museum Weekend Attendees!",
  AAANY:
    "6-Month Free Trial for AAA Western & Central New York Service Providers!",
}

const PageSignUp = (...pageProps) => {
  let sourceParam = (pageProps.length && pageProps[0]["*"]) || null

  let title = signUpSources[String(sourceParam).toUpperCase()] || "Sign Up"
  let description =
    "Experience the best of digital dispatching, mobile applications, sophisticated billing, and our comprehensive reporting, all with no contract, and no setup fees."

  if (sourceParam && sourceParam.startsWith("TB")) {
    title =
      "Friends & Family know best. You've received Towbook for 90-days, free!"
  }

  return (
    <Layout showTrialBanner={false}>
      <PageTitle title={title} description={description}></PageTitle>
      <ContentBlock>
        <SignUpForm source={sourceParam && String(sourceParam).toLowerCase()} />
      </ContentBlock>
    </Layout>
  )
}

export default PageSignUp
